<!--  -->
<template>
  <div class='xuanke' v-loading="loadingPage">
    <div class="round bj-col-white pad">
      <div class="nav">
        <h3>专业科目选择课程</h3>
        <span>选择课程前，请先选择学习年份</span>
      </div>
      <div class="msg">
        <div class="msg-left">
          <el-form ref="form" :model="form" inline>
            <!-- <el-form-item style="margin-right: 20px">
              <template slot="label">
                <div>
                  执业类型
                  <span class="xinhao">*</span>
                </div>
              </template>
              <el-select v-model="type" @change="onTypeChange" style="width: 230px" class="picker" placeholder="与广东执业药师管理系统一致">
                <el-option
                  v-for="item in column"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <template slot="label">
                <div>
                  选择年份
                  <span class="xinhao">*</span>
                </div>
              </template>
              <el-select v-model="form.year" placeholder="请选择" @change="onChange" class="picker">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <span>选课说明：根据人社部《专业技术人员继续教育规定》专业科目不少于60学时，请自由选择课程，达到60学时或以上，方可微信支付。</span>
        </div>
        <!-- <div class="card">
          <img src="../assets/img/code/study-h5.png" alt="">
          <div>按省药监局统一要求，学习中有人脸识别，如电脑无摄像设备，请扫码关注公众号进行学习考试。</div>
        </div> -->
      </div>
      <el-divider></el-divider>
      <div class="list">
        <div v-for="(item, index) in list" :key="index">
          <img :src="item.image" alt="">
          <div>
            <h4>{{item.mingcheng}}</h4>
            <span>授课：{{item.zjr}}</span>
            <span>时长：{{item.shichang | setTime}}分钟</span>
            <span>单位：{{item.danwei}}</span>
            <el-button round plain style="width: 120px" size="mini" type="success" @click="handleItem(index)" v-if="!item.checked">添加</el-button>
            <el-button round style="width: 120px" size="mini" type="success" class="self-button--success" @click="handleItem(index)" v-else-if="info.buxue != 1">取消</el-button>
            <el-button round style="width: 120px" size="mini" type="info" disabled v-else>已选</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-place"></div>
    <div class="footer">
      <div v-if="info.buxue != 1">需要选择60学时或以上，才可微信支付</div>
      <div v-else>专业科目60学时，课程系统已选定</div>
      <div>
        <div v-if="info.buxue != 1">
          已选
          <span>{{checkedNum}}</span>
          门
        </div>
        <div v-if="info.buxue != 1">
          共
          <span>{{checkedTime}}</span>
          学时
        </div>
        <div>共计 ￥{{info.money}}元</div>
        <el-button type="info" disabled v-if="checkedTime < 60 && info.buxue != 1">微信支付</el-button>
        <el-button type="success" class="self-button--success" v-else @click="handlePay">微信支付</el-button>
        <!-- <el-button type="success" class="self-button--success" @click="handlePay">微信支付</el-button> -->
      </div>
    </div>

    <el-dialog
      :visible.sync="enough"
      width="30%"
      center>
      <div class="enough">
        <h3>提示</h3>
        <p>您选择的课程已达到{{checkedTime}}学时，可以进入缴费支付，是否继续选课？</p>
      </div>
      <span slot="footer">
        <div>
          <el-button round type="info" style="width: 160px;margin-right: 50px" @click="enough = false, affirm = true">取消</el-button>
          <el-button round type="success" class="self-button--success" style="width: 160px" @click="enough = false">确认</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="affirm"
      width="30%"
      center>
      <div class="enough">
        <h3>提示</h3>
        <p>您选择的课程提交后将不可修改或调换，确认无误后点击下方的“正确”按钮，需要更换点击“取消”按钮</p>
      </div>
      <span slot="footer">
        <div>
          <el-button round type="info" style="width: 160px;margin-right: 50px" @click="affirm = false">取消</el-button>
          <el-button round type="success" class="self-button--success" style="width: 160px" @click="buyCourse()">确认</el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="buy"
      width="30%"
      center>
      <div class="buy">
        <h3>扫一扫付款</h3>
        <h2>¥{{info.money}}元</h2>
        <!-- <img src="../assets/img/code/study-h5.png" alt=""> -->
        <div ref="qrcode" class="img" v-loading="loading" v-if="buy"></div>
        <div class="hint">
          <img src="../assets/img/buy.png" alt="">
          微信支付
        </div>
      </div>
      <span slot="footer"></span>
    </el-dialog>

    <el-dialog
      :visible.sync="showHint"
      width="30%"
      title="温馨提示"
      center>
      <div class="result">
        <p>非常抱歉，系统检测到您尚未通过当前学年的‘委托书’审核，如果需要报名当前继续教育课程，请先提交‘委托书’待审核通过之后方可进行选课。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round type="success" class="self-button--success" style="width: 160px" @click="$router.push({name: 'Weituo'})">确认</el-button>
        <el-button round type="info" style="width: 160px;margin-right: 50px" @click="showHint = false">取消</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="buyWay"
      width="30%"
      title="支付方式"
      center>
      <div class="buyWay">
        <div>
          <img src="../assets/img/balance.png" alt="">
          <el-radio v-model="way_value" label="1" border>余额支付</el-radio>
        </div>
        <div>
          <img src="../assets/img/weixin.png" alt="">
          <el-radio v-model="way_value" label="2" border>微信支付</el-radio>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round type="success" class="self-button--success" style="width: 160px" @click="handleBuyWay">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="hint"
      width="30%"
      title="温馨提示"
      @close="onClose"
      center>
      <div class="gouke_hint">
        <div>按省药监局的要求：执业药师继续教育<span>专业科目（20学分）</span>和<span>公需科目（10学分）</span>在同一机构报名学习。</div>
        <div>公需课目免费（两个课程包，选择其中一个学习完即可）。</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round type="success" class="self-button--success" style="width: 160px" @click="hint = false">知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Api from '../api/study'
import MApi from '../api/my'
import QRCode from 'qrcodejs2'

export default {
  components: {},
  data() {
    return {
      options: [],
      info: {},
      list: [],
      form: {
        year: ''
      },
      checkedNum: 0,
      checkedTime: 0,
      enough: false,
      enoNum: 0,
      affirm: false,
      buy: false,
      ids: '',
      loading: true,
      buyTime: '',
      loadingPage: true,
      showHint: false,
      column: [],
      type: '',
      buyWay: false,
      way_value: '2',
      hint: false
    };
  },
  props: ['isComponents'],
  filters: {
    setTime (value) {
      return Math.round(value/60)
    }
  },
  computed: {},
  watch: {
    buy () {
      if (!this.buy) {
        clearInterval(this.buyTime)
      }
    }
  },
  created() {
    let userInfo = localStorage.getItem('userInfo')
    userInfo = JSON.parse(userInfo)
    // this.type = userInfo.leixing
    this.column = userInfo.leixing_list
    this.options = userInfo.years.map(item => {
      return {
        label: item,
        value: item
      }
    })
    this.getSumCourseArr()
    this.form.year = localStorage.getItem("year")
    this.onChange()
  },
  mounted() {
    setTimeout(() => {
      // 设置宽度
      // 父级元素宽度的60%
      let w = document.getElementsByClassName('xuanke')[0].clientWidth
      w = this.isComponents ? w : w*0.6
      document.getElementsByClassName('footer')[0].style.width = w + 'px'
      // 获取左边距
      // 父级元素距离页面左边的距离+元素需要距离父级元素左边的距离=元素在父级元素中距离左边的距离
      let left = document.getElementsByClassName('xuanke')[0].offsetLeft
      left = left + document.getElementsByClassName('xuanke')[0].clientWidth*0.2

      // 获取占位元素距离可视窗口顶部的距离
      // 滚动的距离 = 可视窗口顶部距离页面顶部的距离
      // 占位元素距离页面顶部的距离-滚动的距离= 占位元素距离可视窗口顶部的距离（pt）
      let top = document.getElementById("app").scrollTop
      let pt = document.getElementsByClassName('footer-place')[0].offsetTop
      pt = pt - top
      // 当pt大于(可视窗口高度-30)的距离时使元素悬浮
      if (pt > document.documentElement.clientHeight-50) {
        document.getElementsByClassName('footer')[0].style.position = 'fixed'
        document.getElementsByClassName('footer')[0].style.bottom = '30px'
        if (this.isComponents) {
          document.getElementsByClassName('footer')[0].style.left = 'auto'
        } else {
          document.getElementsByClassName('footer')[0].style.left = left+'px'
        }
        document.getElementsByClassName('footer')[0].style.margin = '0'
      }
      document.getElementById('app').onscroll = () => {
        let top = document.getElementById("app").scrollTop
        let pt = document.getElementsByClassName('footer-place')[0].offsetTop
        pt = pt - top
        if (pt > document.documentElement.clientHeight-50) {
          let left = document.getElementsByClassName('xuanke')[0].offsetLeft
          left = left + document.getElementsByClassName('xuanke')[0].clientWidth*0.2
          document.getElementsByClassName('footer')[0].style.position = 'fixed'
          document.getElementsByClassName('footer')[0].style.bottom = '30px'
          if (this.isComponents) {
            document.getElementsByClassName('footer')[0].style.left = 'auto'
          } else {
            document.getElementsByClassName('footer')[0].style.left = left+'px'
          }
          document.getElementsByClassName('footer')[0].style.margin = '0'
        } else if (pt < document.documentElement.clientHeight-50) {
          // 当pt小于(可视窗口高度-30)的距离时使元素固定
          document.getElementsByClassName('footer')[0].style.position = 'relative'
          document.getElementsByClassName('footer')[0].style.bottom = '0'
          document.getElementsByClassName('footer')[0].style.left = '0'
          document.getElementsByClassName('footer')[0].style.margin = '20px auto'
        }    
      }
    }, 500)
  },
  beforeDestroy () {
    this.loadingPage = false
    this.loading = false
    clearInterval(this.buyTime)
  },
  //方法集合
  methods: {
    getSumCourseArr() {
      Api.getSumCourseArr({
        year: this.form.year
      }).then(res => {
        this.loadingPage = false
        this.info = res.data.data
        if (res.data.code == 1) {
          let list = res.data.data.kecheng
          this.checkedNum = 0
          this.checkedTime = 0
          let ids = []
          for (let i in list) {
            list[i].checked = list[i].bixuan == '1' ? true : false
            if (list[i].checked) {
              this.checkedNum++
              this.checkedTime += Math.round(Number(list[i].shichang)/60)
              ids.push(list[i].id)
            }
          }
          this.checkedTime = (this.checkedTime/40).toFixed(0)
          this.ids = ids.join(',')
          this.list = list
        }
      })
    },
    onChange () {
      localStorage.setItem("year", this.form.year)
      this.setUserInfo()
      MApi.getUserInfo().then(res => {
        if (res.status == 200) {
          let info = res.data
          localStorage.setItem('userInfo', JSON.stringify(info))
          if (info.xuanke == 1) {
            if (this.$route.name != 'Kecheng') {
              this.$router.replace({name: 'Kecheng'})
            } else {
              this.$emit("hide")
            }
          } else {
            this.getSumCourseArr()
          }
        } else {
          this.$message.error("用户信息获取失败")
        }
      })
    },
    onTypeChange () {
      MApi.upDateType({
        leixing: this.type
      }).then(res => {
        if (res.data.code == 1) {
          this.$message.success('修改成功')
          this.setUserInfo()
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    handleItem (i) {
      // 委托书部分隐藏
      // let userInfo = localStorage.getItem("userInfo")
      // userInfo = JSON.parse(userInfo)
      // if (userInfo.attorney == '') {
      //   this.showHint = true
      //   return;
      // }
      let list = this.list
      if (list[i].bixuan == '1') {
        return;
      }
      list[i].checked = !list[i].checked
      this.checkedNum = 0
      this.checkedTime = 0
      let ids = []
      for (let i in list) {
        if (list[i].checked) {
          this.checkedNum++
          this.checkedTime += Math.round(Number(list[i].shichang)/60)
          ids.push(list[i].id)
        }
      }
      this.checkedTime = (this.checkedTime/40).toFixed(0)
      this.ids = ids.join(',')
      this.$nextTick(() => {
        this.list = list
        if (this.checkedTime >= 60) {
          if (this.enoNum == 0) {
            this.enough = true
            this.enoNum = 1
          }
        } else {
          this.enoNum = 0
        }
      })
    },
    handlePay () {
      this.affirm = true
    },
    handleBuyWay () {
      this.buyWay = false
      if (this.way_value == '2') {
        this.buyCourse()
        return;
      }
      Api.payCourse({str: this.ids}).then(res => {
        if (res.data.code == 1) {
          this.hint = true
          // this.$router.replace({name: 'BuyOk', query: {type: 1}})
          // this.$router.replace({name: 'Gongxu'})
        }
      })
    },
    buyCourse () {
      this.buy = true
      this.affirm = false
      this.loading = true
      Api.buyCourse({str: this.ids}).then(res => {
        setTimeout(() => {
          var qrcode = new QRCode(this.$refs.qrcode, {
            width: 200,
            height: 200,
          });
          qrcode.makeCode(res.data.code_url);
          this.loading = false
          this.buyTime = setInterval(() => {
            this.buyResult(res.data.order_card)
          }, 1000);
        }, 500)
      })
    },
    buyResult (card) {
      Api.buyResult({
        order_card: card
      }).then(res => {
        if (res.data.code == 1) {
          this.loadingPage = true
          clearInterval(this.buyTime)
          if (this.form.year == 2020) {
            this.$alert('2020年公需科目无课程学习，广东省执业药师注册从2021年开始提供公需科目。', '温馨提示', {
              confirmButtonText: '确认',
              callback: () => {
                this.onClose()
              }
            });
          } else {
            this.buy = false
            this.hint = true
          }
          // this.$router.replace({name: 'BuyOk', query: {type: 1}})
          // this.$router.replace({name: 'Gongxu'})
        }
      })
    },
    onClose () {
      this.$nextTick(() => {
        this.$router.replace({name: 'BuyOk', query: {type: 1, year: this.form.year}})
      })
    }
  },
}
</script>

<style lang='less' scoped>
.xuanke {
  .nav {
    display: flex;
    align-items: flex-end;
    span {
      margin-left: 10px;
      color: @default;
    }
  }
  /deep/ .el-form-item__label {
    font-weight: bold;
    font-size: 16px;
  }
  .msg {
    margin: 30px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    .msg-left {
      width: 65%;
      > span {
        display: block;
        margin-top: 20px;
      }
      .xinhao {
        color: red;
      }
      /deep/ .el-input__icon {
        font-size: 20px;
      }
    }
    .card {
      display: flex;
      align-items: center;
      img {
        width: 120px;
      }
      div {
        font-size: 15px;
        margin-left: 10px;
        width: 150px;
      }
    }
  }
  .list {
    padding: 30px 0 20px;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 420px;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      img {
        width: 150px;
      }
      > div {
        margin-left: 15px;
        h4,span {
          display: block;
          margin-bottom: 10px;
        }
        span {
          color: #999;
        }
      }
    }
  }
  .footer {
    width: 60%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    height: 70px;
    border-radius: 35px;
    background-color: #ffffff;
    border: 1px solid @default;
    overflow: hidden;
    > div:first-child {
      line-height: 70px;
      padding: 0 35px;
      background-color: #F0F0F0;
      font-weight: bold;
    }
    > div:last-child {
      flex: 1;
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        color: @default;
      }
    }
  }

  .enough {
    h3 {
      text-align: center;
    }
    p {
      text-align: center;
      margin: 25px 0 20px;
    }
  }
  .buy {
    h3, h2 {
      text-align: center;
    }
    h2 {
      color: @default;
      margin: 30px 0 40px;
    }
    > .img {
      width: 200px;
      height: 200px;
      margin: 0 auto 20px;
      display: block;
    }
    .hint {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }
  
  .buyWay {
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 40px;
        margin-right: 10px;
      }
    }
  }

  .gouke_hint {
    div {
      text-indent: 33px;
      margin-bottom: 10px;
    }
    span {
      color: red;
    }
  }
}
</style>